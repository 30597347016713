<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="property-list-element relative flex items-center px-2 py-2" 
         :class="{
             'property-list-element--disabled': !property.is_property_active
         }" 
         @dblclick="create()"
    >

        <div class="property-list-element__help-tootltip mr-2 -mt-px">
            <Popper class="flex items-center" arrow>
                <lsn-icon iname="lsn-information-outline" />
                <template #content>
                    <span class="text-xs">{{ property.description }}</span>
                </template>
            </Popper>
        </div>

        <div class="property-list-element__content">

            <!-- label -->
            <div class="property-list-element__label">
                {{ property.label }}
            </div>

            <component :is="getPropertyComponent(property)" v-if="property.id" :property="property"></component>

        </div>

        <template v-if="property.id">
            <!-- disable button -->
            <div class="property-list-element__disable-btn" @click="showDeleteConfirmation = true">
                <lsn-icon iname="lsn-close" />
            </div>
        </template>
        <template v-else>
            <!-- activate button -->
            <div class="list-element__hover">
                <div class="text-primary-400 text-sm">
                    double-click to enable
                </div>
            </div>
        </template>

    </div>

    <!-- delete (disable) confirmation -->
    <teleport to="body">
        <lsn-modal :is-open="showDeleteConfirmation">
            <div class="lsn-prompt">

                <div class="lsn-prompt-content">
                    <div class="lsn-prompt-content__question text-red-600">
                        Do you really want to remove this property ?
                    </div>
                    <div class="lsn-prompt-content__text">
                        This action cannot be undone.
                    </div>
                </div>

                <div class="lsn-prompt-footer justify-around py-4">
                    <button class="lsn-btn  lsn-btn--gray-outline" @click="showDeleteConfirmation = false">
                        cancel
                    </button>
                    <button class="lsn-btn lsn-btn--danger" @click="remove()">
                        delete
                    </button>
                </div>

            </div>
        </lsn-modal>
    </teleport>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import BooleanProperty from './BooleanProperty';
import DefaultProperty from './DefaultProperty';
import InputNumberProperty from './InputNumberProperty';
import InputTextProperty from './InputTextProperty';
import InputTextareaProperty from './InputTextareaProperty';
import ListOptionProperty from './ListOptionProperty';

export default
{
    name: 'BaseProperty',

    props:
    {
        property:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['selected'],

    data()
    {
        return {
            showDeleteConfirmation: false
        };
    },

    methods:
    {
        getPropertyComponent(property)
        {
            switch(property.pkey)
            {
                case 'is_active':
                case 'is_enabled':
                case 'access':
                case 'grouped':
                case 'required':
                    return BooleanProperty;
                    break;

                case 'min':
                case 'max':
                case 'step':
                    return InputNumberProperty;
                    break;

                case 'default_value':
                case 'fixed_value':
                    return InputTextProperty;
                    break;

                case 'form_label':
                case 'note':
                case 'help':
                    return InputTextareaProperty;
                    break;

                case 'list_options':
                    return ListOptionProperty;
                    break;

                default:
                    return DefaultProperty;
                    break;
            }
        },

        create()
        {
            if(!this.property.id)
            {
                this.$store.dispatch('prototype/createProperty', {
                    pkey:            this.property.pkey,
                    pvalue:          1,
                    prototype_id:    this.$route.params.prototype_id,
                    parameter_id:    this.$route.params.parameter_id,
                    design_group_id: this.$route.params.design_group_id,
                });
            }
        },

        update(pvalue)
        {
            this.$store.dispatch('prototype/updateProperty', {
                id:     this.property.id,
                pvalue: pvalue,
            });
        },

        remove()
        {
            this.$store.dispatch('prototype/removeProperty', this.property).then(() =>
            {
                this.showDeleteConfirmation = false;
            });
        },

        selectProperty()
        {
            if(this.property.id)
            {
                this.$emit('selected', this.property);
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

::v-deep .lsn-form__switch-wrapper
{
    margin-bottom: 0 !important;
}

.list-element__hover
{
    @apply bg-opacity-70;
}

</style>
