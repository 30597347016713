<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="app-layout__wrapper flex">

        <!-- navbar -->
        <div class="lsn-side-primary-menu bg-black">

            <!-- app switch -->
            <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right bg-black">
                <lsn-icon iname="lsn-apps" class="hw-7"></lsn-icon>
                <div class="lsn-tooltip__content bg-black py-2 px-3">
                    Applications
                </div>
            </div>

            <!-- home -->
            <router-link v-slot="{ navigate, isActive }" :to="{name: 'home'}" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{'lsn-side-primary-menu__item--active': isActive}" @click="navigate">
                    <lsn-icon iname="lsn-home" class="hw-7"></lsn-icon>
                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Home
                    </div>
                </div>
            </router-link>

            <!-- prototype -->
            <router-link v-slot="{ navigate, isActive }" :to="{name: 'prototypes'}" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{'lsn-side-primary-menu__item--active': isActive}" @click="navigate">
                    <lsn-icon iname="lsn-flask-outline" class="hw-7"></lsn-icon>
                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Prototypes
                    </div>
                </div>
            </router-link>

            <!-- bottom menu items -->
            <div class="absolute bottom-0 w-full">

                <!-- add bottom menu here -->
                    
                <!-- account-menu -->
                <account-floating-menu></account-floating-menu>

            </div>

        </div>

        <!-- app main area -->
        <router-view></router-view>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import AccountFloatingMenu from './AccountFloatingMenu';

export default
{
    name: 'AppLayout',
    
    components:
    {
        AccountFloatingMenu
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.app-layout__wrapper
{
    @apply absolute left-0 top-0 w-full overflow-hidden;
}

</style>
