/* ================
 * Prototype Module
 * ================
 */

import * as _ from 'lodash';
import axios from '@/axios';

export function setupPrototypeModule(VueUserConfigurations, VueHoneybadger)
{
    return {
        namespaced: true,

        // ------------------------------------------------------------------------- STATE

        state()
        {
            return {
                // Prototype related
                prototypeParameters: [],
                parameterProperties: [],

                // Property related
                propertyListOptions: [],    // Available list options for one parameter property
                
                // Parameter related
                allParameterListOptions: [], // All possible list options for one parameter

                // Possible values
                allPrototypes:         [], // All existing prototypes 
                allParameters:         [], // All existing parameters
                allDesignGroups:       [], // All existing design groups 
                allProperties:         [], // All existing parameter properties
                allArticles:           [], // All articles
                formDisplays:          [], // All possible "form_display" values
                formatters:            [], // All possible "formatters" values
                orderKeys:             [], // All possible "order_key" values
                valueTypes:            [], // All possible "pvalue_type" values
                designGroupCategories:
                [
                    'LEVEL',
                    'COMPANY',
                    'INTERNAL',
                    'COUNTRY',
                    'ENTITY_SUBTYPE',
                    'ENTITY'
                ],
            };
        },

        // ------------------------------------------------------------------------- GETTERS

        getters:
        {
            listOption(state, getters, rootState)
            {
                return _.find(state.propertyListOptions, {'id': parseInt(rootState.route.params.option_id)});
            },
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            /* ---------- prototype ---------- */

            setAllPrototypes(state, prototypes)
            {
                state.allPrototypes = prototypes;
            },

            addPrototype(state, prototype)
            {
                state.allPrototypes.push(prototype);
            },

            updatePrototype(state, prototype)
            {
                let index = _.findIndex(state.allPrototypes, ['id', prototype.id]);

                if(!_.isUndefined(index))
                {
                    state.allPrototypes.splice(index, 1, prototype);
                }
            },

            /* ---------- parameter ---------- */

            setAllParameters(state, parameters)
            {
                state.allParameters = parameters;
            },

            setPrototypeParameters(state, parameters)
            {
                state.prototypeParameters = parameters;
            },

            addParameter(state, parameter)
            {
                state.parameters.push(parameter);
            },

            updateParameter(state, parameter)
            {
                let index = _.findIndex(state.parameters, ['id', parameter.id]);

                if(!_.isUndefined(index))
                {
                    state.parameters.splice(index, 1, parameter);
                }
            },

            /* ---------- design group ---------- */

            setAllDesignGroups(state, designGroups)
            {
                state.allDesignGroups = designGroups;
            },

            addDesignGroup(state, designGroup)
            {
                state.allDesignGroups.push(designGroup);
            },

            /* ---------- property ---------- */

            setAllProperties(state, properties)
            {
                _.each(properties, (property) =>
                {
                    property.is_property_active = false;
                });

                state.allProperties = properties;
            },

            setParameterProperties(state, properties)
            {
                state.parameterProperties = properties;
            },

            toggleProperty(state, pkey)
            {
                _.each(state.properties, (property) =>
                {
                    if(property.pkey == pkey)
                    {
                        property.is_property_active = !property.is_property_active;
                        return;
                    }
                });
            },

            addPropertyToCurrentParameter(state, property)
            {
                
            },

            updatePropertyToCurrentParameter(state, property)
            {
                
            },

            removePropertyFromCurrentParameter(state, property)
            {
                
            },

            /* ---------- list options ---------- */

            setParameterAllListOptions(state, listOptions)
            {
                state.allParameterListOptions = listOptions;
            },

            setPropertyListOptions(state, listOptions)
            {
                state.propertyListOptions = listOptions;
            },

            updatePropertyListOption(state, listOption)
            {
                let index = _.findIndex(state.propertyListOptions, ['id', listOption.id]);

                state.propertyListOptions.splice(index, 1, listOption);
            },

            /* ---------- articles ---------- */

            setAllArticles(state, articles)
            {
                state.allArticles = articles;
            },

            /* ---------- possible values ---------- */

            setFormDisplays(state, formDisplays)
            {
                state.formDisplays = formDisplays;
            },

            setFormatters(state, formatters)
            {
                state.formatters = formatters;
            },

            setFormOrderKeys(state, orderKeys)
            {
                state.orderKeys = orderKeys;
            },
            
            setFormValueTypes(state, valueTypes)
            {
                state.valueTypes = valueTypes;
            }
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            /* ---------- prototype ---------- */

            fetchAllPrototypes({commit})
            {
                return axios.get('/api/prototype').then((response) =>
                {
                    commit('setAllPrototypes', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            setPrototypesOrder({}, data)
            {
                return axios.put('/api/prototype/order', data);
            },

            /* ---------- parameter ---------- */

            fetchAllParameters({commit})
            {
                return axios.get('/api/parameter').then((response) =>
                {
                    commit('setAllParameters', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            fetchPrototypeParameters({commit}, prototype_id)
            {
                let url = ('/api/parameter/prototype/:prototype_id').replace(':prototype_id', prototype_id);

                return axios.get(url).then((response) =>
                {
                    commit('setPrototypeParameters', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            addParameterToCurrentPrototype({}, {prototype_id, parameter_id})
            {
                let url = '/api/parameter/:parameter_id/prototype/:prototype_id';
                url = url.replace(':parameter_id', parameter_id);
                url = url.replace(':prototype_id', prototype_id);

                return axios.post(url);
            },

            removeParameterFromCurrentPrototype({}, {prototype_id, parameter_id})
            {
                let url = '/api/parameter/:parameter_id/prototype/:prototype_id';
                url = url.replace(':parameter_id', parameter_id);
                url = url.replace(':prototype_id', prototype_id);

                return axios.delete(url);
            },

            setCurrentPrototypeParametersOrder({}, {parametersOrder, prototype_id})
            {
                let url = '/api/parameter/order/prototype/:prototype_id';
                url = url.replace(':prototype_id', prototype_id);

                return axios.put(url, parametersOrder);
            },

            /* ---------- design group ---------- */

            fetchAllDesignGroups({commit})
            {
                return axios.get('/api/design-group').then((response) =>
                {
                    commit('setAllDesignGroups', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            /* ---------- property ---------- */

            fetchAllProperties({commit})
            {
                return axios.get('/api/property/class').then((response) =>
                {
                    commit('setAllProperties', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            fetchParameterProperties({commit}, {prototype_id, parameter_id})
            {
                let url = ('/api/property/prototype/:prototype_id/parameter/:parameter_id');
                url = url.replace(':prototype_id', prototype_id);
                url = url.replace(':parameter_id', parameter_id);
                
                return axios.get(url).then((response) =>
                {
                    commit('setParameterProperties', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            createProperty({commit}, property)
            {
                return axios.post('/api/property', property).then((response) =>
                {
                    commit('addPropertyToCurrentParameter', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            // updateProperty({commit}, property)
            // {
            //     let url = ('/api/property/:property_id').replace(':property_id', property.id);

            //     return axios.put(url, property).then((response) =>
            //     {
            //         commit('updatePropertyToCurrentParameter', response.data);
            //     }).catch((error) =>
            //     {
            //         console.log(error);
            //     });
            // },

            // removeProperty({commit}, property)
            // {
            //     let url = ('/api/property/:property_id').replace(':property_id', property.id);

            //     return axios.delete(url).then((response) =>
            //     {
            //         commit('removePropertyToCurrentParameter', property);
            //         return response;
            //     }).catch((error) =>
            //     {
            //         console.log(error);
            //     });
            // },

            /* ---------- list options ---------- */

            fetchParameterAllListOptions({commit}, parameter_id)
            {
                let url = '/api/list-option/parameter/:parameter_id';
                url = url.replace(':parameter_id', parameter_id);

                return axios.get(url).then((response) =>
                {
                    commit('setParameterAllListOptions', response.data);
                    return response;
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            fetchPropertyListOptions({commit}, property_id)
            {
                let url = '/api/list-option/property/:property_id';
                url = url.replace(':property_id', property_id);

                return axios.get(url).then((response) =>
                {
                    commit('setPropertyListOptions', response.data);
                    return response;
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            updatePropertyListOption({commit}, listOption)
            {
                let url = ('/api/list-option/available/:id').replace(':id', listOption.id);

                return axios.put(url, listOption).then((response) =>
                {
                    commit('updatePropertyListOption', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            /* ---------- articles ---------- */

            fetchAllArticles({commit})
            {
                return axios.get('/api/article').then((response) =>
                {
                    commit('setAllArticles', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },

            /* ---------- form display ---------- */

            fetchConstantValues({commit})
            {
                return axios.get('/api/constant').then((response) =>
                {
                    commit('setFormDisplays', response.data.formDisplays);
                    commit('setFormatters', response.data.formatters);
                    commit('setFormOrderKeys', response.data.orderKeys);
                    commit('setFormValueTypes', response.data.valueTypes);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },
        }
    };
};