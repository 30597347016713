<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div v-if="!loading" class="column">
        
        <!-- column header -->
        <div class="column-header">
            <div v-if="cPrototype.id" class="column-header__title">
                Edit a prototype
            </div>
            <div v-else class="column-header__title">
                Create a prototype
            </div>
        </div>

        <!-- from -->
        <div class="column-form">

            <!-- is active -->
            <div class="column-form__line">
                <lsn-switch v-model="cPrototype.is_active" label="Is active ?" :error="errors.is_active" />
            </div>

            <!-- code -->
            <div class="column-form__line">
                <lsn-input v-model="cPrototype.code" label="Code *" :error="errors.code" />
            </div>

            <!-- label -->
            <div class="column-form__line">
                <lsn-input v-model="cPrototype.label" label="Label *" :error="errors.label" />
            </div>

            <!-- version -->
            <div class="column-form__line">
                <lsn-input v-model="cPrototype.version" label="Version *" placeholder="1.0.0" :error="errors.version" />
            </div>

            <!-- valid from -->
            <div class="column-form__line">
                <lsn-input v-model="cPrototype.valid_from" label="Valid from *" placeholder="YYYY-MM-DD" :error="errors.valid_from" />
            </div>

            <!-- valid until -->
            <div class="column-form__line">
                <lsn-input v-model="cPrototype.valid_until" label="Valid until" placeholder="YYYY-MM-DD" :error="errors.valid_until" />
            </div>

            <!-- prototype class -->
            <div class="column-form__line">
                <lsn-input v-model="cPrototype.prototype_class" label="Prototype PHP class" :error="errors.prototype_class" />
            </div>

            <!-- icom -->
            <div class="column-form__line">
                <lsn-textarea v-model="cPrototype.icom" label="Internal comment" />
            </div>

            <!-- save button -->
            <div class="column-form__line mx-3 mt-8">
                <div class="lsn-btn lsn-btn--primary" @click="save()">
                    sauvegarder
                </div>
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import axios from '@/axios';

export default
{
    name: 'PrototypeForm',

    data()
    {
        return {
            loading:    true,
            cPrototype: null,
            errors:     {}
        };
    },

    computed:
    {
        prototypes()
        {
            return this.$store.state.prototype.allPrototypes;
        }
    },

    watch:
    {
        '$route.params.prototype_id': function()
        {
            this.setCurrentPrototype();
        }
    },

    created()
    {
        this.setCurrentPrototype();
    },

    methods:
    {
        setCurrentPrototype()
        {
            if(this.$route.params.prototype_id)
            {
                let prototypeId = parseInt(this.$route.params.prototype_id);
                this.cPrototype = _.clone(_.find(this.prototypes, ['id', prototypeId]));
            }
            else
            {
                this.cPrototype = {
                    id:              null,
                    is_active:       false,
                    code:            null,
                    label:           null,
                    version:         null,
                    prototype_class: null,
                    valid_from:      null,
                    valid_until:     null,
                    icom:            null
                };
            }

            this.loading = false;
        },

        save()
        {
            if(!this.cPrototype.id)
            {
                axios.post('/api/prototype', this.cPrototype).then((response) =>
                {
                    this.$store.commit('prototype/addPrototype', response.data);
                }).catch((error) =>
                {
                    this.errors = error.response.errors;
                });
            }
            else
            {
                let url = ('/api/prototype/:prototype_id').replace(':prototype_id', this.cPrototype.id);

                return axios.put(url, this.cPrototype).then((response) =>
                {
                    this.$store.commit('prototype/updatePrototype', response.data);
                }).catch((error) =>
                {
                    this.errors = error.response.errors;
                });
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

::v-deep .lsn-form__label
{
    @apply text-gray-500 text-sm;
}

</style>
