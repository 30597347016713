<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <!-- current prototype parameters list -->
    <div class="column">

        <!-- list header -->
        <div class="column-header">
            <div class="column-header__title">
                List option's property
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- options -->
        <div class="list-content">
            <is-active-option-property :list-option="listOption" @updated="updated"></is-active-option-property>
            <is-enabled-option-property :list-option="listOption" @updated="updated"></is-enabled-option-property>
            <required-article-codes-option-property :list-option="listOption" @article:unselected="removeArticleFromRequiredArticleCodes"></required-article-codes-option-property>
            <priced-article-code-option-property :list-option="listOption" @article:unselected="removePricedArticleCode"></priced-article-code-option-property>
        </div>

    </div>

    <articles-list v-if="articlesListOpen" 
                   :selected-article-codes="selectedArticleCodes"
                   @article:selected="onArticleSelected"
                   @article:unselected="onArticleUnselected"
    >
    </articles-list>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import IsActiveOptionProperty from './IsActiveOptionProperty';
import IsEnabledOptionProperty from './IsEnabledOptionProperty';
import RequiredArticleCodesOptionProperty from './RequiredArticleCodesOptionProperty';
import PricedArticleCodeOptionProperty from './PricedArticleCodeOptionProperty';
import ArticlesList from '@/views/prototypes/ArticlesList';

export default
{
    name: 'ListOptionProperties',

    components:
    {
        IsActiveOptionProperty,
        IsEnabledOptionProperty,
        RequiredArticleCodesOptionProperty,
        PricedArticleCodeOptionProperty,
        ArticlesList
    },

    computed:
    {
        listOption()
        {
            return this.$store.getters['prototype/listOption'];
        },

        articlesListOpen()
        {
            return this.$route.query['required-articles-list-open'] || this.$route.query['priced-articles-list-open'];
        },

        selectedArticleCodes()
        {
            if(this.$route.query['required-articles-list-open'])
            {
                return this.listOption.required_article_codes;
            }
            else if(this.$route.query['priced-articles-list-open'])
            {
                return [this.listOption.priced_article_code];
            }
            else
            {
                return [];
            }
        }
    },

    methods:
    {
        updated(propertyKey, propertyValue)
        {
            let listOption = _.clone(this.listOption);
            listOption[propertyKey] = propertyValue;

            this.$store.dispatch('prototype/updatePropertyListOption', listOption);
        },

        onArticleSelected(articleCode)
        {
            if(this.$route.query['priced-articles-list-open'])
            {
                this.updated('priced_article_code', articleCode);
            }
            else
            {
                this.addArticleFromRequiredArticleCodes(articleCode);
            }
        },

        onArticleUnselected(articleCode)
        {
            if(this.$route.query['priced-articles-list-open'])
            {
                this.removePricedArticleCode();
            }
            else
            {
                this.removeArticleFromRequiredArticleCodes(articleCode);
            }
        },

        addArticleFromRequiredArticleCodes(articleCode)
        {
            let requiredArticleCodes = _.clone(this.listOption.required_article_codes);

            requiredArticleCodes.push(articleCode);
            requiredArticleCodes = _.uniq(requiredArticleCodes);

            this.updated('required_article_codes', requiredArticleCodes);
        },

        removeArticleFromRequiredArticleCodes(articleCode)
        {
            let requiredArticleCodes = _.clone(this.listOption.required_article_codes);

            let index = requiredArticleCodes.indexOf(articleCode);

            if(index == -1)
            {
                return;
            }

            requiredArticleCodes.splice(index, 1);

            this.updated('required_article_codes', requiredArticleCodes);
        },

        removePricedArticleCode()
        {
            this.updated('priced_article_code', null);
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.column
{
    width: 350px;
}

</style>
