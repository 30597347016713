<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div v-if="!loading" class="column">
        
        <!-- column header -->
        <div class="column-header">
            <div v-if="cParameter.id" class="column-header__title">
                Edit a parameter
            </div>
            <div v-else class="column-header__title">
                Create a parameter
            </div>
        </div>

        <!-- from -->
        <div class="column-form">

            <!-- code -->
            <div class="column-form__line">
                <lsn-input v-model="cParameter.code" label="Code *" :error="errors.code" />
            </div>

            <!-- label -->
            <div class="column-form__line">
                <lsn-input v-model="cParameter.label" label="Label *" :error="errors.label" />
            </div>

            <!-- pvalue type -->
            <div class="column-form__line">
                <lsn-select v-model="cParameter.pvalue_type"
                            :options="valueTypes"
                            label="Value type *"
                            :error="errors.pvalue_type"
                />
            </div>

            <!-- formatters -->
            <div class="column-form__line">
                <lsn-data-list id="formatters"
                               v-model="cParameter.formatters" 
                               label="Formatting"
                               :options="formatters"
                               :error="errors.formatters"
                />
            </div>

            <!-- order key -->
            <div class="column-form__line">
                <lsn-select v-model="cParameter.order_key"
                            label="Order attribute key *"
                            :options="orderKeys"
                            :error="errors.order_key"
                />
            </div>

            <!-- unit -->
            <div class="column-form__line">
                <lsn-input v-model="cParameter.unit" label="Unit" :error="errors.unit" />
            </div>

            <!-- preserve_during_reorder -->
            <div class="column-form__line">
                <lsn-switch v-model="cParameter.preserve_during_reorder" label="Preserve value on re-order" :error="errors.preserve_during_reorder" />
            </div>

            <!-- parameter class -->
            <div class="column-form__line">
                <lsn-input v-model="cParameter.parameter_class" label="Parameter PHP class" :error="errors.parameter_class" />
            </div>

            <!-- description -->
            <div class="column-form__line">
                <lsn-textarea v-model="cParameter.description" label="Description" />
            </div>

            <!-- save button -->
            <div class="column-form__line mx-3 mt-8">
                <div class="lsn-btn lsn-btn--primary" @click="save()">
                    sauvegarder
                </div>
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import axios from '@/axios';

export default
{
    name: 'ParameterForm',

    data()
    {
        return {
            loading:    true,
            cParameter: null,
            errors:     {}
        };
    },

    computed:
    {
        parameters()
        {
            return this.$store.state.prototype.parameters;
        },

        formDisplay()
        {
            return this.$store.state.prototype.formDisplays;
        },

        formatters()
        {
            return this.$store.state.prototype.formatters;
        },

        orderKeys()
        {
            return this.$store.state.prototype.orderKeys;
        },

        valueTypes()
        {
            return this.$store.state.prototype.valueTypes;
        }
    },

    watch:
    {
        '$route.params.parameter_id': function()
        {
            this.setCurrentParameter();
        }
    },

    created()
    {
        this.setCurrentParameter();
    },

    methods:
    {
        setCurrentParameter()
        {
            if(this.$route.params.parameter_id)
            {
                let parameterId = parseInt(this.$route.params.parameter_id);
                this.cParameter = _.clone(_.find(this.parameters, ['id', parameterId]));
            }
            else
            {
                this.cParameter = {
                    id:                      null,
                    code:                    null,
                    label:                   null,
                    pvalue_type:             null,
                    unit:                    null,
                    parameter_class:         null,
                    formatters:              null,
                    order_key:               null,
                    preserve_during_reorder: false,
                    description:             null,
                    icom:                    null
                };
            }

            this.loading = false;
        },

        save()
        {
            if(!this.cParameter.id)
            {
                axios.post('/api/parameter', this.cParameter).then((response) =>
                {
                    this.$store.commit('prototype/addParameter', response.data);
                }).catch((error) =>
                {
                    this.errors = error.response.errors;
                });
            }
            else
            {
                // let url = ('/api/parameter/:parameter_id').replace(':parameter_id', this.cParameter.id);

                // return axios.put(url, this.cParameter).then((response) =>
                // {
                //     this.$store.commit('prototype/updateParameter', response.data);
                // }).catch((error) =>
                // {
                //     this.errors = error.response.errors;
                // });
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.column
{
    width: 300px;
}

::v-deep .lsn-form__label
{
    @apply text-gray-500 text-sm;
}

</style>
