<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="column">

        <!-- list header -->
        <div class="column-header">
            <div class="column-header__title">
                Articles <span class="column-header__title-count">( {{ articles.length }} )</span>
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- not in use parameters -->
        <perfect-scrollbar class="list-content">
            <div 
                v-for="article in articles" 
                :key="article.id" 
                class="list-element relative"
                :class="{'list-element--selected': isSelected(article.code)}"
                @dblclick="toggleArticle(article)"
            >

                <div class="list-element__content py-1 px-2">
                    <div class="list-element__label w-full" v-html="article.label"></div>
                    <div class="list-element__code w-full">
                        {{ article.code }}
                    </div>
                </div>

                <div class="list-element__hover">
                    <div class="text-primary-400 text-sm">
                        double-click to add
                    </div>
                </div>
                
            </div>
        </perfect-scrollbar>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'ArticlesList',

    props:
    {
        selectedArticleCodes:
        {
            type:    Array,
            default: function()
            {
                return [];
            }
        }
    },

    emits: ['article:selected', 'article:unselected'],

    computed:
    {
        articles()
        {
            return this.$store.state.prototype.allArticles;
        }
    },

    methods:
    {
        isSelected(code)
        {
            return this.selectedArticleCodes.indexOf(code) !== -1;
        },

        toggleArticle(article)
        {
            if(this.isSelected(article.code))
            {
                this.$emit('article:unselected', article.code);
            }
            else
            {
                this.$emit('article:selected', article.code);
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.column
{
    width: 400px;
}

</style>