<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <!-- current prototype parameters list -->
    <div class="column">

        <!-- list header -->
        <div class="column-header">
            <div class="column-header__title">
                Parameter's property
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- property -->
        <perfect-scrollbar class="list-content">
            <!-- <is-active-property></is-active-property> -->
            <base-property v-for="cProperty in combinedProperties" 
                           :key="cProperty.pkey" 
                           :property="cProperty"
            >
            </base-property>
        </perfect-scrollbar>

    </div>

    <router-view></router-view>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import IsActiveProperty from './IsActiveProperty.vue';
import BaseProperty from './BaseProperty.vue';

export default
{
    name: 'PropertiesList',

    components:
    {
        BaseProperty,
        IsActiveProperty
    },

    computed:
    {
        allProperties()
        {
            return this.$store.state.prototype.allProperties;
        },

        parameterProperties()
        {
            return this.$store.state.prototype.parameterProperties;
        },

        combinedProperties()
        {
            let combinedProperties = [];

            for(let i in this.allProperties)
            {
                let cProperty = this.allProperties[i];
                let combinedProperty = _.clone(cProperty);
                let cParameterProperty = _.find(this.parameterProperties, {'pkey': cProperty.pkey, 'design_group_id': parseInt(this.$route.params.design_group_id)});

                if(cParameterProperty)
                {
                    combinedProperty = _.merge({}, combinedProperty, cParameterProperty, {is_property_active: true});
                }

                combinedProperties.push(combinedProperty);
            }
            console.log('COMBINED PROPERTIES',combinedProperties);
            return combinedProperties;
        },
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.list-element--disabled
{
    @apply bg-gray-200;
}

.list-element--disabled:hover::after
{
    content: 'double-click to enable';
    @apply text-sm text-gray-500;
}

.list-element--disabled:hover .list-element__label
{
    @apply hidden;
}

</style>
