//------------------------------------------------------------------------------ VUE

import { createApp } from 'vue';

//------------------------------------------------------------------------------ STYLE

import './assets/styles/main.scss';

//------------------------------------------------------------------------------ ICONS

import './assets/lsn-sprite.svg';

//------------------------------------------------------------------------------ PERFECT-SCROLLBAR

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//------------------------------------------------------------------------------ VUE-POPPER

import Popper from "vue3-popper";

//------------------------------------------------------------------------------ HONEYBADGER

// WARING : report only in "production" environment.
import { createHoneybadger } from './utils/vue-honeybadger';

const VueHoneybadger = createHoneybadger({apiKey: 'hbp_xF8LId68tNfDdAAUIuP4ch9aXORPU50OwKxB', environment: process.env.NODE_ENV});

//------------------------------------------------------------------------------ VUE-CONFIGURATIONS

import { createVueConfigurations } from 'vue-configurations';

const VueApplicationConfigurations = createVueConfigurations();
const VueUserConfigurations = createVueConfigurations();

//------------------------------------------------------------------------------ STORE

import { setupStore } from './store';

const store = setupStore(VueApplicationConfigurations, VueUserConfigurations, VueHoneybadger);

//------------------------------------------------------------------------------ ROUTER

import { setupRouter } from './router';

const router = setupRouter(store);

//------------------------------------------------------------------------------ STORE-ROUTER-SYNC

import { sync } from 'vuex-router-sync';
sync(store, router);

//------------------------------------------------------------------------------ LAUSANNE COMPONENTS

import { 
    LsnCheckboxBox,
    LsnClickOutside, 
    LsnIcon, 
    LsnDataList,
    LsnInput,
    LsnModal,
    LsnRadioBox,
    LsnSelect,
    LsnSider,
    LsnSwitch,
    LsnTextarea
} from 'vue-lausanne';

//------------------------------------------------------------------------------ APP AND STORE

import App from './App.vue';

//------------------------------------------------------------------------------ APP BUILDING

store.dispatch('auth/check').finally(() =>
{
    const app = createApp(App)
        .use(store)
        .use(router)
        .use(VueHoneybadger)
        .use(PerfectScrollbar)
        .use(VueApplicationConfigurations, {globalPropertyKey: '$aconfig'})
        .use(VueUserConfigurations, {globalPropertyKey: '$uconfig'})
        .directive('click-outside', LsnClickOutside)
        .component("Popper", Popper)
        .component('LsnCheckboxBox', LsnCheckboxBox)
        .component('LsnDataList', LsnDataList)
        .component('LsnIcon', LsnIcon)
        .component('LsnInput', LsnInput)
        .component('LsnModal', LsnModal)
        .component('LsnRadioBox', LsnRadioBox)
        .component('LsnSelect', LsnSelect)
        .component('LsnSider', LsnSider)
        .component('LsnSwitch', LsnSwitch)
        .component('LsnTextarea', LsnTextarea);

    // Save asked URL to redirect to it after initialization.
    let redirectTo = window.location.pathname;
    let queryParams = new URLSearchParams(window.location.search);
    let query = {};

    queryParams.forEach((value, key) =>
    {
        query[key] = value;
    });

    query.redirect = redirectTo;

    let route = router.resolve(window.location.pathname);
    let params = {};

    if(route.name == 'login' || route.name == 'initialize')
    {
        delete query.redirect;
    }
    
    if(!store.state.auth.authenticated)
    {
        router.push({name: 'login', params: params, query: query});
    }
    else
    {
        router.push({name: 'initializing', params: params, query: query});
    }

    router.isReady().then(() =>
    {
        app.mount('#app');
    });
});