<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="ml-auto">
        <input v-model.number="pvalue" type="number" class="field" />
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'InputNumberProperty',

    props:
    {
        property:
        {
            type:     Object,
            required: true
        }
    },

    data()
    {
        return {
            showDeleteConfirmation: false
        };
    },

    computed:
    {
        pvalue:
        {
            get()
            {
                return this.property.pvalue;
            },
            set(pvalue)
            {
                this.update(pvalue);
            }
        }
    },

    methods:
    {

    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.field
{
    width: 100px;
    @apply px-2 py-1 border border-gray-300 text-primary-500 text-center outline-none appearance-none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]
{
    -moz-appearance:textfield;
}

</style>
