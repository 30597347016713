<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="w-full">
        <textarea v-model.lazy="pvalue" type="text" class="field"></textarea>
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'InputTextareaProperty',

    props:
    {
        property:
        {
            type:     Object,
            required: true
        }
    },

    data()
    {
        return {
            showDeleteConfirmation: false
        };
    },

    computed:
    {
        pvalue:
        {
            get()
            {
                return this.property.pvalue;
            },
            set(pvalue)
            {
                this.update(pvalue);
            }
        }
    },

    methods:
    {

    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.field
{
    @apply w-full px-2 py-1 border border-gray-300 text-primary-500 text-xs outline-none appearance-none resize-none;
}


</style>
