<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <!-- icon (in primary side menu) -->
    <div class="lsn-side-primary-menu__item" @click.stop="showMenu = true">
        <lsn-icon iname="lsn-account-circle-outline" class="hw-8"></lsn-icon>
    </div>

    <!-- menu -->
    <div v-show="showMenu" class="lsn-floating-menu">
        <div v-click-outside="hideMenu">
            <div class="flex flex-wrap items-center justify-center py-4 border-b border-gray-300">
                <div class="w-full text-center pb-4">
                    <img class="inline rounded-full" :src="avatarSrc" />
                </div>
                <div v-if="fullname" class="w-full text-center pb-1">
                    {{ fullname }}
                </div>
                <div class="w-full text-center text-xs">
                    {{ cUser.email }}
                </div>
            </div>
            <div class="lsn-floating-menu__item">
                my account
            </div>
            <div class="lsn-floating-menu__item" @click="logout()">
                sign out
            </div>
        </div>
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'AccountFloatingMenu',

    data()
    {
        return {
            showMenu: false
        };
    },

    computed:
    {
        cUser()
        {
            return this.$store.state.account.cUser;
        },

        fullname()
        {
            let cUser = this.cUser;

            if(cUser.first_name && cUser.last_name)
            {
                return cUser.first_name + ' ' + cUser.last_name;
            }
            else
            {
                return null;
            }
        },

        avatarSrc()
        {
            return 'https://www.gravatar.com/avatar/' + this.cUser.email_hash + '?s=60&d=mp';
        }
    },

    methods:
    {
        hideMenu()
        {
            this.showMenu = false;
        },

        logout()
        {
            this.$store.dispatch('auth/logout');
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">



</style>
