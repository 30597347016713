<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="column">

        <!-- list header -->
        <div class="column-header">
            <div class="column-header__title">
                Available parameters <span class="column-header__title-count">( {{ notInUseParameters.length }} )</span>
            </div>
            <div class="column-header__btn-list">
                <div class="column-header__btn" @click="closeParameterSelectionList()">
                    <lsn-icon iname="lsn-arrow-left" />
                </div>
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- not in use parameters -->
        <perfect-scrollbar class="list-content">
            <div v-for="parameter in notInUseParameters" :key="parameter.id" class="list-element relative">

                <div class="list-element__content py-1 px-2" @dblclick="addParameterToPrototype(parameter)">
                    <div class="list-element__label w-full" v-html="parameter.label"></div>
                    <div class="list-element__code w-full">
                        {{ parameter.code }}
                    </div>
                </div>

                <div class="list-element__hover">
                    <div class="text-primary-400 text-sm">
                        double-click to add
                    </div>
                </div>
                
            </div>
        </perfect-scrollbar>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'ParametersList',

    props:
    {
        prototypeParameters:
        {
            type:     Array,
            required: true
        }
    },
    
    emits: ['parameter:add'],

    computed:
    {
        notInUseParameters()
        {
            let allParameters = this.$store.state.prototype.allParameters;
            let inUseParameterCodes = _.map(this.prototypeParameters, 'code');
            let notInUseParameters = [];

            _.each(allParameters, (parameter) =>
            {
                if(inUseParameterCodes.indexOf(parameter.code) === -1)
                {
                    notInUseParameters.push(parameter);
                }
            });

            return _.sortBy(notInUseParameters, ['label']);
        }
    },

    methods:
    {
        closeParameterSelectionList()
        {
            this.$router.push({
                name:   'prototype-parameters', 
                params: {prototype_id: this.$route.params.prototype_id},
            });
        },

        addParameterToPrototype(parameter)
        {
            this.$emit('parameter:add', parameter);
        },
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
