<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="ml-auto">
        <div @click="openOptionsList()">
            VIEW LIST
        </div>
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'ListOptionProperty',

    props:
    {
        property:
        {
            type:     Object,
            required: true
        }
    },

    methods:
    {
        openOptionsList()
        {
            this.$store.dispatch('prototype/fetchPropertyListOptions', this.property.id).then(() =>
            {
                this.$router.push({
                    name:   'prototype-parameter-design-group-property-list-options',
                    params: _.merge({}, this.$route.params, {property_id: this.property.id})
                });
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
