/**********
 * Router *
 *********/

import * as _ from 'lodash';
import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';

export function setupRouter(store)
{
    //-------------------------------------------------------------------------- CONSTRUCTOR

    const router = createRouter({
        history: createWebHistory(),
        base:    process.env.VUE_APP_API_LOCATION,
        routes
    });

    //-------------------------------------------------------------------------- GUARD 2 : authentication

    router.beforeEach((to, from) =>
    {
        let isAuthenticated = store.state.auth.authenticated;

        if(to.meta.auth && !isAuthenticated)
        {
            console.log('GUARD 2: redirect to login');

            return {
                name:   'login', 
                params: to.params,
                query:  to.query
            };
        }
        else if(!to.meta.auth && isAuthenticated)
        {
            console.log('GUARD 2: redirect to home');

            return {
                name:   'home', 
                params: to.params,
                query:  to.query
            };
        }
        else
        {
            console.log('GUARD 2: ok', to);

            return true;
        }
    });

    //--------------------------------------------------------------------------

    return router;
}