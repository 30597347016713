<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <!-- design group list -->
    <div class="column">

        <!-- column header -->
        <div class="column-header">
            <div class="column-header__title">
                Design groups <span class="column-header__title-count">( {{ allDesignGroups.length }} )</span>
            </div>
            <div class="column-header__btn-list">
                <div class="column-header__btn" @click="newDesignGroup()">
                    <lsn-icon iname="lsn-plus" />
                </div>
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- design group categories list -->
        <perfect-scrollbar class="list-content">
            <div v-for="category in designGroupCategories" :key="category">

                <!-- category label -->
                <div class="w-full text-xs text-gray-400 py-1 px-2 bg-gray-100 border-b border-gray-200">
                    {{ category }}
                </div>

                <!-- design groups list -->
                <div v-for="designGroup in getDesignGroupsByCategory(category)" 
                     :key="designGroup.id" 
                     class="list-element" 
                     :class="{'list-element--selected': designGroup.id == $route.params.design_group_id}" 
                     @click="selectDesignGroup(designGroup)"
                >
                    <!-- content -->
                    <div class="list-element__content py-1 px-2">
                        <div class="list-element__label w-full" v-html="designGroup.label"></div>
                        <div class="list-element__code w-full">
                            {{ designGroup.code }}
                        </div>
                    </div>

                    <!-- suffix -->
                    <div class="list-element__suffix px-2">
                        <div class="list-element__badge" :class="{'list-element__badge--disabled': !getNbOfProperties(designGroup)}">
                            {{ getNbOfProperties(designGroup) }}
                        </div>
                    </div>

                </div>

            </div>
        </perfect-scrollbar>

    </div>

    <router-view></router-view>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'DesignGroup',

    computed:
    {
        designGroupCategories()
        {
            return this.$store.state.prototype.designGroupCategories;
        },

        allDesignGroups()
        {
            return this.$store.state.prototype.allDesignGroups;
        },

        parameterProperties()
        {
            return this.$store.state.prototype.parameterProperties;
        }
    },

    methods:
    {
        getDesignGroupsByCategory(category)
        {
            let designGroups = _.filter(this.allDesignGroups, ['category', category]);

            return _.sortBy(designGroups, ['label']);
        },
        
        getNbOfProperties(designGroup)
        {
            return _.filter(this.parameterProperties, ['design_group_id', designGroup.id]).length;
        },
        
        selectDesignGroup(designGroup)
        {
            this.$router.push({
                name:   'prototype-parameter-design-group-property', 
                params: _.assign({}, this.$route.params, {design_group_id: designGroup.id})
            });
        },

        newDesignGroup()
        {
            this.$router.push({
                name:   'prototype-parameter-design-group-new', 
                params:
                {
                    prototype_id: this.$route.params.prototype_id,
                    parameter_id: this.$route.params.parameter_id
                }
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.list-element__badge
{
    width: 30px;
    @apply rounded-full bg-primary-100 text-center text-xs text-primary-700 py-1;
}

.list-element__badge--disabled
{
    @apply bg-gray-200 text-gray-400;
}

.list-element--selected .list-element__badge
{
    @apply bg-primary-300 text-primary-800;
}

</style>
