<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div v-if="!loading" class="column">
        
        <!-- column header -->
        <div class="column-header">
            <div v-if="cDesignGroup.id" class="column-header__title">
                Edit a design group
            </div>
            <div v-else class="column-header__title">
                Create a design group
            </div>
        </div>

        <!-- from -->
        <div class="column-form">

            <!-- code -->
            <div class="column-form__line">
                <lsn-input v-model="cDesignGroup.code" label="Code *" :error="errors.code" />
            </div>

            <!-- label -->
            <div class="column-form__line">
                <lsn-input v-model="cDesignGroup.label" label="Label *" :error="errors.label" />
            </div>

            <!-- pvalue type -->
            <div class="column-form__line">
                <lsn-select v-model="cDesignGroup.category"
                            :options="designGroupCategories"
                            label="Category *"
                            :error="errors.category"
                />
            </div>

            <!-- description -->
            <div class="column-form__line">
                <lsn-textarea v-model="cDesignGroup.description" label="Description" />
            </div>

            <!-- save button -->
            <div class="column-form__line mx-3 mt-8">
                <div class="lsn-btn lsn-btn--primary" @click="save()">
                    sauvegarder
                </div>
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import axios from '@/axios';

export default
{
    name: 'DesignGroupForm',

    data()
    {
        return {
            loading:      true,
            cDesignGroup: null,
            errors:       {}
        };
    },

    computed:
    {
        designGroups()
        {
            return this.$store.state.prototype.designGroups;
        },
        
        designGroupCategories()
        {
            return this.$store.state.prototype.designGroupCategories;
        },
    },

    watch:
    {
        '$route.params.design_group_id': function()
        {
            this.setCurrentDesignGroup();
        }
    },

    created()
    {
        this.setCurrentDesignGroup();
    },

    methods:
    {
        setCurrentDesignGroup()
        {
            if(this.$route.params.design_group_id)
            {
                let designGroupId = parseInt(this.$route.params.design_group_id);
                this.cDesignGroup = _.clone(_.find(this.designGroups, ['id', designGroupId]));
            }
            else
            {
                this.cDesignGroup = {
                    id:          null,
                    code:        null,
                    label:       null,
                    category:    null,
                    description: null,
                };
            }

            this.loading = false;
        },

        save()
        {
            if(!this.cDesignGroup.id)
            {
                axios.post('/api/design-group', this.cDesignGroup).then((response) =>
                {
                    this.$store.commit('prototype/addDesignGroup', response.data);
                    this.$router.push({
                        name:   'prototype-parameter-design-group-property', 
                        params:
                        {
                            prototype_id:    this.$route.params.prototype_id,
                            parameter_id:    this.$route.params.parameter_id,
                            design_group_id: response.data.id
                        }
                    });
                }).catch((error) =>
                {
                    this.errors = error.response.errors;
                });
            }
            else
            {
                // let url = ('/api/design-group/:parameter_id').replace(':parameter_id', this.cDesignGroup.id);

                // return axios.put(url, this.cDesignGroup).then((response) =>
                // {
                //     this.$store.commit('prototype/updateParameter', response.data);
                // }).catch((error) =>
                // {
                //     this.errors = error.response.errors;
                // });
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.column
{
    width: 300px;
}

::v-deep .lsn-form__label
{
    @apply text-gray-500 text-sm;
}

</style>
