<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="lsn-main-area">
        
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Home',
    
    components:
    {

    },

    methods:
    {

    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">



</style>
