<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="property-list-element flex items-center px-2 py-2">

        <div class="property-list-element__help-tootltip mr-2 -mt-px">
            <Popper class="flex items-center" arrow>
                <lsn-icon iname="lsn-information-outline" />
                <template #content>
                    <span class="text-xs">is enable ?</span>
                </template>
            </Popper>
        </div>

        <div class="property-list-element__content">

            <!-- label -->
            <div class="property-list-element__label">
                Is enable ?
            </div>

            <div class="ml-auto">
                <lsn-switch v-model="is_enabled" class="lsn-form__wrapper--padless" />
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'IsEnabledOptionProperty',

    props:
    {
        listOption:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['updated'],

    computed:
    {
        is_enabled:
        {
            get()
            {
                return this.listOption.is_enabled;
            },
            set(isEnabled)
            {
                this.$emit('updated', 'is_enabled', isEnabled);
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

::v-deep .lsn-form__switch-wrapper
{
    margin-bottom: 0 !important;
}

</style>
