/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

import AppLayout from '@/layouts/AppLayout';
import Home from '@/views/Home';
import Initializing from '@/views/Initializing';
import Login from '@/views/Login';
import PrototypesList from '@/views/prototypes/PrototypesList';
import PrototypeForm from '@/views/prototypes/PrototypeForm';
import PrototypeParametersList from '@/views/prototypes/parameters/PrototypeParametersList';
import ParameterForm from '@/views/prototypes/parameters/ParameterForm';
import DesignGroupsList from '@/views/prototypes/parameters/design-group/DesignGroupsList';
import DesignGroupForm from '@/views/prototypes/parameters/design-group/form';
import PropertiesList from '@/views/prototypes/parameters/design-group/property/PropertiesList';
import ListOption from '@/views/prototypes/parameters/design-group/property/list-option';
import ListOptionProperty from '@/views/prototypes/parameters/design-group/property/list-option/list-option-property';
import ArticlesList from '@/views/prototypes/ArticlesList';

export default[

    // -----------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------- UNAUTHENTICATED
    // -----------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------- LOGIN

    {
        path:      '/login',
        name:      'login',
        component: Login,
        meta:      { auth: false } // If the user needs to be a guest to view this page.
    },

    // ---------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------- AUTHENTICATED
    // ---------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------- INITIALISATION

    {
        path:      '/initializing',
        name:      'initializing',
        component: Initializing,
        meta:      { auth: true }
    },

    // ------------------------------------------------------------------------- BASE

    {
        path:      '/',
        name:      'base',
        component: AppLayout,
        meta:      { auth: true }, // If the user needs to be authenticated to view this page.

        children:
        [
            // ----------------------------------------------------------------- HOME

            {
                path:      'home',
                name:      'home',
                component: Home
            },

            // ----------------------------------------------------------------- PROTOTYPES

            {
                path:      'prototype',
                name:      'prototypes',
                component: PrototypesList,
                children:
                [
                    // --------------------------------------------------------- PROTOTYPE MANAGEMENT

                    {
                        path:      'new',
                        name:      'prototype-new',
                        component: PrototypeForm,
                    },

                    {
                        path:      ':prototype_id/edit',
                        name:      'prototype-edit',
                        component: PrototypeForm,
                    },

                    // --------------------------------------------------------- PROTOTYPE PARAMETER

                    {
                        path:      ':prototype_id/parameter',
                        name:      'prototype-parameters',
                        component: PrototypeParametersList,
                        children:
                        [
                            // ------------------------------------------------- PARAMETER MANAGEMENT

                            {
                                path:      'new',
                                name:      'prototype-parameter-new',
                                component: ParameterForm,
                            },

                            // ------------------------------------------------- PROTOTYPE PARAMETER DESIGN GROUP

                            {
                                path:      ':parameter_id/design-group',
                                name:      'prototype-parameter-design-groups',
                                component: DesignGroupsList,
                                children:
                                [
                                    // ----------------------------------------- DESIGN GROUP MANAGEMENT

                                    {
                                        path:      'new',
                                        name:      'prototype-parameter-design-group-new',
                                        component: DesignGroupForm,
                                    },

                                    // ----------------------------------------- PROTOTYPE PARAMETER DESIGN GROUP PROPERTY

                                    {
                                        path:      ':design_group_id/property',
                                        name:      'prototype-parameter-design-group-property',
                                        component: PropertiesList,
                                        children:
                                        [
                                            // --------------------------------- PROTOTYPE PARAMETER DESIGN GROUP PROPERTY LIST OPTIONS

                                            {
                                                path:      ':property_id/list-options',
                                                name:      'prototype-parameter-design-group-property-list-options',
                                                component: ListOption,
                                                children:
                                                [
                                                    {
                                                        path:      'option/:option_id/option-parameter',
                                                        name:      'prototype-parameter-design-group-property-list-options-parameter',
                                                        component: ListOptionProperty,
                                                    }
                                                ]
                                            },

                                            // --------------------------------- PROTOTYPE PARAMETER DESIGN GROUP PROPERTY CALCULATED VALUE

                                            // {
                                            //     path:      ':property_id/calculated-value',
                                            //     name:      'prototype-parameter-design-group-property-calculated-value',
                                            //     component: ListOption
                                            // },

                                            // --------------------------------- PROTOTYPE PARAMETER DESIGN GROUP PROPERTY PRICED ARTICLE CODE

                                            // {
                                            //     path:      ':property_id/priced-article-code',
                                            //     name:      'prototype-parameter-design-group-priced-article-code',
                                            //     component: ListOption
                                            // },

                                            // --------------------------------- PROTOTYPE PARAMETER DESIGN GROUP PROPERTY REQUIRED ARTICLE CODES

                                            // {
                                            //     path:      ':property_id/required-article-code',
                                            //     name:      'prototype-parameter-design-group-required-article-code',
                                            //     component: ListOption
                                            // },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
        ]
    },

    // --------------------------------------------------------------------------------
    // ----------------------------------------------------------------- DEFAULT ROUTES
    // --------------------------------------------------------------------------------

    {
        path:     '/',
        redirect: { name: 'login' }
    },

    {
        path:     '/*',
        redirect: { name: 'login' }
    }
];
