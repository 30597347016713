<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="ml-auto">
        <lsn-switch v-model="pvalue" class="lsn-form__wrapper--padless" />
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'BooleanProperty',

    props:
    {
        property:
        {
            type:     Object,
            required: true
        }
    },

    computed:
    {
        pvalue:
        {
            get()
            {
                switch(this.property.pvalue)
                {
                    case '1':
                    case 1:
                    case 'Y':
                    case 'true':
                    case true:
                        return true;
                        break;

                    default:
                        return false;
                        break;
                }
            },
            set(pvalue)
            {
                this.update(pvalue);
            }
        }
    },

    methods:
    {

    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

::v-deep .lsn-form__switch-wrapper
{
    margin-bottom: 0 !important;
}

</style>
