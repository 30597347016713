<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="property-list-element flex flex-wrap items-center px-2 py-2">

        <div class="property-list-element__help-tootltip mr-2 -mt-px">
            <Popper class="flex items-center" arrow>
                <lsn-icon iname="lsn-information-outline" />
                <template #content>
                    <span class="text-xs">Option will be displayed only if entity has one of these articles in its catalog.</span>
                </template>
            </Popper>
        </div>

        <div class="property-list-element__content">

            <!-- label -->
            <div class="property-list-element__label">
                Required article codes
            </div>

            <!-- open button -->
            <div class="open-articles-list-btn" @click="openArticlesList">
                <lsn-icon iname="lsn-arrow-right-bold" />
            </div>

        </div>

        <div v-for="required_article_code in required_article_codes" 
             :key="required_article_code" 
             class="article-code" 
             @dblclick="$emit('article:unselected', required_article_code)"
        >
            - {{ required_article_code }}
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'RequiredArticleCodesOptionProperty',

    props:
    {
        listOption:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['article:unselected'],

    computed:
    {
        required_article_codes()
        {
            return this.listOption.required_article_codes;
        }
    },

    methods:
    {
        openArticlesList()
        {
            this.$router.push({
                query: {'required-articles-list-open': true}
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.article-code
{
    @apply w-full py-1 text-xs text-primary-500 overflow-y-hidden overflow-ellipsis cursor-pointer;
}

.article-code:hover
{
    @apply bg-primary-10;
}

.open-articles-list-btn
{
    @apply ml-auto text-primary-500 cursor-pointer;
}

.open-articles-list-btn svg
{
    height: 25px;
    width: 25px;
}

</style>
