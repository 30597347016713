<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="property-list-element flex flex-wrap items-center px-2 py-2">

        <div class="property-list-element__help-tootltip mr-2 -mt-px">
            <Popper class="flex items-center" arrow>
                <lsn-icon iname="lsn-information-outline" />
                <template #content>
                    <span class="text-xs">Price of current article will be displayed next to the option label (if entity has it in its catalog).</span>
                </template>
            </Popper>
        </div>

        <div class="property-list-element__content">

            <!-- label -->
            <div class="property-list-element__label">
                Priced article code
            </div>

            <!-- open button -->
            <div class="open-articles-list-btn" @click="openArticlesList">
                <lsn-icon iname="lsn-arrow-right-bold" />
            </div>

        </div>

        <div v-if="priced_article_code" class="article-code" @dblclick="$emit('article:unselected', priced_article_code)">
            {{ priced_article_code }}
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'PricedArticleCodeOptionProperty',

    props:
    {
        listOption:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['article:unselected'],

    computed:
    {
        priced_article_code()
        {
            return this.listOption.priced_article_code;
        }
    },

    methods:
    {
        openArticlesList()
        {
            this.$router.push({
                query: {'priced-articles-list-open': true}
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.article-code
{
    @apply w-full py-1 text-xs text-primary-500 overflow-y-hidden overflow-ellipsis cursor-pointer;
}

.article-code:hover
{
    @apply bg-primary-10;
}

.open-articles-list-btn
{
    @apply ml-auto text-primary-500 cursor-pointer;
}

.open-articles-list-btn svg
{
    height: 25px;
    width: 25px;
}

</style>
