/*******************
 * Main Vuex Store *
 ******************/

import * as _ from 'lodash';
import axios from '@/axios';
import { createStore } from 'vuex';
import AjaxStack from 'ajaxstack';

// Modules
import { setupAccountModule } from './account';
import { setupAuthModule } from './auth';
import { setupPrototypeModule } from './prototype';

// Actions called during application initialization
const initializationActions = [
    {action: 'account/fetchCurrentCompany'},
    {action: 'fetchApplicationConfigurations'},
    // {action: 'auth/autoRefreshAccessToken'},
    {action: 'auth/checkIdle'}
];

export function setupStore(VueApplicationConfigurations, VueUserConfigurations, VueHoneybadger)
{
    const store = createStore({

        /**
         * Assign the modules to the store.
         */
        modules:
        {
            account:   setupAccountModule(VueUserConfigurations, VueHoneybadger),
            auth:      setupAuthModule(VueUserConfigurations),
            prototype: setupPrototypeModule(VueUserConfigurations),
        },

        // ------------------------------------------------------------------------- STATE

        state()
        {
            return {};
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            /* ---------- application configurations ---------- */

            /**
             * Set global application configurations (from app_config table).
             * @param {Object} state
             * @param {Object} configurations
             */
            setApplicationConfigurations(state, configurations)
            {
                VueApplicationConfigurations.initialize(configurations);
            }
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            initialize({dispatch})
            {
                return dispatch('account/fetchConnectedUser').then(() =>
                {
                    let initializationAjaxStack = new AjaxStack(initializationActions, dispatch, { concurrency: 3 });
                    
                    return initializationAjaxStack.run({});
                });
            },

            /* ---------- application configurations ---------- */

            fetchApplicationConfigurations({commit})
            {
                return axios.get('/api/application-configurations').then(response =>
                {
                    commit('setApplicationConfigurations', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            }
        }
    });

    return store;
};
