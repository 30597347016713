<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="ml-auto">
        
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'DefaultProperty',

    props:
    {
        property:
        {
            type:     Object,
            required: true
        }
    },

    methods:
    {
        enable()
        {
            this.$store.commit('prototype/toggleProperty', this.property.pkey);
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
