<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div v-if="!loading" class="lsn-main-area flex">
        
        <!-- column -->
        <div class="column">

            <!-- column header -->
            <div class="column-header">
                <div class="column-header__title">
                    Prototypes <span class="column-header__title-count">( {{ allPrototypes.length }} )</span>
                </div>
                <div class="column-header__btn-list">
                    <div class="column-header__btn" @click="newPrototype()">
                        <lsn-icon iname="lsn-plus" />
                    </div>
                </div>
            </div>

            <!-- list search -->
            <div class="list-search">
                <!-- @todo : search field -->
            </div>

            <!-- prototypes list -->
            <perfect-scrollbar class="list-content">
                <draggable v-model="allPrototypes"
                           item-key="id"
                           :component-data="{name: !dragging ? 'flip-list' : null}"
                           ghost-class="ghost"
                           handle=".list-element__handle"
                           @start="dragging = true" 
                           @end="dragging = false"
                >
                    <template #item="{ element }">
                        <div class="list-element" 
                             :class="{
                                 'list-element--selected': element.id == $route.params.prototype_id,
                                 'list-element--active': element.is_active
                             }"
                        >

                            <!-- prefix (sort handle) -->
                            <div class="list-element__prefix list-element__handle">
                                <div class="list-element__btn">
                                    <lsn-icon iname="lsn-sort" />
                                </div>
                            </div>

                            <!-- content -->
                            <div class="list-element__content py-2 px-2" @click="selectPrototype(element)">
                                <div class="list-element__label w-full" v-html="element.label"></div>
                                <div class="list-element__code w-full">
                                    {{ element.code }}
                                </div>
                            </div>

                            <!-- suffix (delete button) -->
                            <div class="list-element__suffix">
                                <div class="list-element__btn" @click="editPrototype(element)">
                                    <lsn-icon iname="lsn-pencil" />
                                </div>
                            </div>

                        </div>
                    </template>
                </draggable>
            </perfect-scrollbar>

        </div>

        <router-view></router-view>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import Draggable from 'vuedraggable';

export default
{
    name: 'PrototypesList',

    components:
    {
        Draggable,
    },

    data()
    {
        return {
            loading:  true,
            dragging: false,
        };
    },

    computed:
    {
        allPrototypes:
        {
            get()
            {
                return this.$store.state.prototype.allPrototypes;
            },
            set(prototypes)
            {
                let prototypesOrder = [];

                for(let i in prototypes)
                {
                    prototypesOrder.push({
                        id:    prototypes[i].id,
                        order: i
                    });
                }

                this.$store.dispatch('prototype/setPrototypesOrder', prototypesOrder);
                this.$store.commit('prototype/setAllPrototypes', prototypes);
            }
        }
    },

    watch:
    {
        '$route': function()
        {
            this.$nextTick(() =>
            {
                this.scrollToRight();
            });
        }
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            this.loading = true;

            let promises = [
                this.$store.dispatch('prototype/fetchAllPrototypes'),
                this.$store.dispatch('prototype/fetchAllParameters'),
                this.$store.dispatch('prototype/fetchAllDesignGroups'),
                this.$store.dispatch('prototype/fetchAllProperties'),
                this.$store.dispatch('prototype/fetchConstantValues'),
                this.$store.dispatch('prototype/fetchAllArticles')
            ];

            if(this.$route.params.prototype_id)
            {
                promises.push(this.$store.dispatch('prototype/fetchPrototypeParameters', this.$route.params.prototype_id));
            }

            if(this.$route.params.prototype_id && this.$route.params.parameter_id)
            {
                promises.push(this.$store.dispatch('prototype/fetchParameterProperties', {
                    prototype_id: this.$route.params.prototype_id,
                    parameter_id: this.$route.params.parameter_id
                }));

                promises.push(this.$store.dispatch('prototype/fetchParameterAllListOptions', this.$route.params.parameter_id));
            }

            if(this.$route.params.property_id)
            {
                promises.push(this.$store.dispatch('prototype/fetchPropertyListOptions', this.$route.params.property_id));
            }

            Promise.all(promises).then(() =>
            {
                this.loading = false;
                
                this.$nextTick(() =>
                {
                    this.scrollToRight();
                });
            });
        },

        selectPrototype(prototype)
        {
            this.$store.dispatch('prototype/fetchPrototypeParameters', prototype.id).then(() =>
            {
                this.$router.push({
                    name:   'prototype-parameters', 
                    params: {
                        prototype_id: prototype.id
                    }
                });
            });
        },

        newPrototype()
        {
            this.$router.push({name: 'prototype-new'});
        },

        editPrototype(prototype)
        {
            this.$router.push({name: 'prototype-edit', params: {prototype_id: prototype.id}});
        },

        scrollToRight()
        {
            var mainArea = this.$el;
            mainArea.scrollLeft = mainArea.scrollWidth;
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.lsn-main-area
{
    height: 100vh;
    width: calc(100vw - 50px);
    overflow-y: scroll;
}

.column
{
    width: 350px;
}

.list-element
{
    border-right-color: theme('colors.gray.400');
    @apply border-r-4;
}

.list-element--active
{
    border-right-color: theme('colors.green.400');
}

.flip-list-move
{
    transition: transform 0.5s;
}

.no-move
{
    transition: transform 0s;
}

.ghost
{
    opacity: 0.5;
    background: #c8ebfb;
}

</style>
