/* ============
 * Auth Module
 * ============
 */


import * as _ from 'lodash';
import { Idle } from 'idlejs';
import axios from '@/axios';
import moment from 'moment';

var refreshAccessTokenTimerId = null;

export function setupAuthModule(VueUserConfigurations)
{
    return {
        namespaced: true,

        state()
        {
            return {
                authenticated:  false, // If current user is authenticated
                sessionEndAtTs: null   // Token expiration date as timestamp UNIX (in seconds)
            };
        },

        mutations:
        {
            /**
             * Set authenticated.
             * @param {Object} state
             * @param {Boolean} status
             */
            setAuthenticationStatus(state, status)
            {
                state.authenticated = status;
            },

            /**
             * Set application as authenticated, save access token in
             * local storage and add token to HTTP header.
             * @param {Object} state
             * @param {string} token
             */
            login(state, tokens)
            {
                // Avoid setting a bad token.
                if(tokens.refresh_token)
                {
                    state.authenticated = true;
                    localStorage.setItem('refresh_token', tokens.refresh_token);
                }
            },

            setAccessToken(state, tokens)
            {
                // Avoid setting a bad token.
                if(tokens.access_token)
                {
                    localStorage.setItem('access_token', tokens.access_token);
                    
                    // Set session expire timestamp from JWT token.
                    let payload = (tokens.access_token).split('.')[1];
                    payload = JSON.parse(window.atob(payload));
                    state.sessionEndAtTs = payload.exp;

                    // Set Axios token
                    axios.defaults.headers.common.Authorization = `Bearer ${tokens.access_token}`;
                }
            },

            setSsoCookie(state, ssoToken)
            {
                // Cookie.setItem('sso_token', ssoToken, 86400, null, process.env.VUE_APP_AUTH_API_SSO_DOMAIN);
            }
        },

        actions:
        {
            /**
             * Check if tokens exists (so a session).
             * @param {Object} dispatch
             * @param {Object} commit
             */
            check({commit})
            {
                let accessToken = localStorage.getItem('access_token');
                let refreshToken = localStorage.getItem('refresh_token');
                
                if(!_.isNull(accessToken) && !_.isNull(refreshToken))
                {
                    let parts = (accessToken).split('.');
                    let payload = JSON.parse(atob(parts[1]));
                    let expirationDate = moment.unix(payload.exp);

                    console.log('--- TOKEN EXPIRATION', expirationDate.format('DD.MM.YYYY HH:mm:ss'));

                    // If current access token is not expired => login.
                    if(!expirationDate.isBefore(moment()))
                    {
                        let tokens = {access_token: accessToken, refresh_token: refreshToken};
        
                        commit('login', tokens);
                        commit('setAccessToken', tokens);

                        console.log('-- ALREADY LOGGED IN');
                    }
                }
            },

            authenticate({commit}, credentials)
            {
                var data = {
                    app_code:   process.env.VUE_APP_AUTH_API_APP_CODE,
                    app_secret: process.env.VUE_APP_AUTH_API_APP_SECRET,
                    username:   credentials.username,
                    password:   credentials.password
                };

                return axios.post(process.env.VUE_APP_AUTH_API_LOGIN_LOCATION, data).then(response =>
                {
                    commit('login', response.data);
                    commit('setAccessToken', response.data);
                    // commit('setSsoCookie', response.data.sso_token);
                });
            },

            autoRefreshAccessToken({commit, state})
            {
                refreshAccessTokenTimerId = setInterval(() => 
                {
                    let currentTs = Date.now() / 1000; // Timestamp PHP are in seconds not in milliseconds as in Javascript.
                    
                    // If token will expire in the next 3 minutes.
                    if( (state.sessionEndAtTs - currentTs) < 180 )
                    {
                        var data = {
                            app_code:      process.env.VUE_APP_AUTH_API_APP_CODE,
                            app_secret:    process.env.VUE_APP_AUTH_API_APP_SECRET,
                            refresh_token: localStorage.getItem('refresh_token')
                        };

                        axios.post(process.env.VUE_APP_AUTH_API_REFRESH_LOCATION, data).then(response =>
                        {
                            commit('setAccessToken', response.data);
                        }).catch((error) =>
                        {
                            console.log(error.response);
                        });
                    }
                },
                1200); // Every 2 minutes. 
            },

            checkIdle({dispatch})
            {
                const idle = new Idle();

                idle.whenNotInteractive()
                    .within(VueUserConfigurations.get('session_duration', 20))
                    .do(() =>
                    {
                        dispatch('logout');
                    })
                    .start();

                return true;
            },

            logout()
            {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                // Cookie.removeItem('sso_token', null, process.env.VUE_APP_AUTH_API_SSO_DOMAIN);

                location.reload();
            }

            //------------------------------------------------------------------ SSO (futur)

            // ssoLogin({commit})
            // {
            //     var data = {
            //         app_code:   process.env.VUE_APP_AUTH_API_APP_CODE,
            //         app_secret: process.env.VUE_APP_AUTH_API_APP_SECRET,
            //         sso_token:  Cookie.getItem('sso_token')
            //     };

            //     return axios.$http.post(process.env.VUE_APP_AUTH_API_SSO_LOGIN_LOCATION, data).then(response =>
            //     {
            //         commit('login', response.data);
            //         commit('SET_ACCESS_TOKEN', response.data);
            //         commit('SET_SSO_TOKEN', response.data.sso_token);

            //     }).catch(function(error)
            //     {
            //         console.log(error.response);
            //     });
            // },
        }
    };
};
