<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="column">

        <!-- list header -->
        <div class="column-header">
            <div class="column-header__title">
                Prototype's parameters <span class="column-header__title-count">( {{ prototypeParameters.length }} )</span>
            </div>
            <div class="column-header__btn-list">
                <!-- <div class="column-header__btn" @click="newParameter()">
                    <lsn-icon iname="lsn-plus" />
                </div> -->
                <div class="column-header__btn" @click="openParameterSelectionList()">
                    <lsn-icon iname="lsn-playlist-plus" />
                </div>
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- current prototype parameters -->
        <perfect-scrollbar class="list-content">
            <draggable v-model="prototypeParameters"
                       item-key="id"
                       :component-data="{name: !dragging ? 'flip-list' : null}"
                       ghost-class="ghost"
                       handle=".list-element__handle"
                       @start="dragging = true" 
                       @end="dragging = false"
            >
                <template #item="{ element }">
                    <div>
                        <prototype-parameters-list-item :parameter="element" @parameter:remove="removeParameterFromPrototype"></prototype-parameters-list-item>
                    </div>
                </template>
            </draggable>
        </perfect-scrollbar>
        
    </div>

    <!-- not in use parameters -->
    <parameters-list v-if="parametersListOpen" :prototype-parameters="prototypeParameters" @parameter:add="addParameterToPrototype"></parameters-list>

    <router-view></router-view>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import Draggable from 'vuedraggable';
import * as _ from 'lodash';
import PrototypeParametersListItem from './PrototypeParametersListItem';
import ParametersList from './ParametersList';

export default
{
    name: 'PrototypeParametersList',

    components:
    {
        Draggable,
        PrototypeParametersListItem,
        ParametersList
    },

    data()
    {
        return {
            dragging:           false,
            showParametersList: false
        };
    },

    computed:
    {
        prototypeParameters:
        {
            get()
            {
                return this.$store.state.prototype.prototypeParameters;
            },
            set(prototypeParameters)
            {
                let parametersOrder = [];

                for(let i in prototypeParameters)
                {
                    parametersOrder.push({
                        parameter_id: prototypeParameters[i].id,
                        order:        i
                    });
                }

                this.$store.dispatch('prototype/setCurrentPrototypeParametersOrder', {
                    parametersOrder: parametersOrder,
                    prototype_id:    this.$route.params.prototype_id
                });
                this.$store.commit('prototype/setPrototypeParameters', prototypeParameters);
            }
        },

        parametersListOpen()
        {
            return this.$route.query['parameters-list-open'];
        }
    },

    methods:
    {
        newParameter()
        {
            this.$router.push({name: 'prototype-parameter-new'});
        },

        openParameterSelectionList()
        {
            this.$router.push({
                name:   'prototype-parameters', 
                params: {prototype_id: this.$route.params.prototype_id},
                query:  {'parameters-list-open': true}
            });
        },

        addParameterToPrototype(parameter)
        {
            this.prototypeParameters.push(parameter);

            this.$store.dispatch('prototype/addParameterToCurrentPrototype', {
                prototype_id: this.$route.params.prototype_id,
                parameter_id: parameter.id
            });
        },

        removeParameterFromPrototype(parameter)
        {
            let index = _.findIndex(this.prototypeParameters, ['id', parameter.id]);
            this.prototypeParameters.splice(index, 1);

            this.$store.dispatch('prototype/removeParameterFromCurrentPrototype', {
                prototype_id: this.$route.params.prototype_id,
                parameter_id: parameter.id
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.column
{
    width: 400px;
}

.flip-list-move
{
    transition: transform 0.5s;
}

.no-move
{
    transition: transform 0s;
}

.ghost
{
    opacity: 0.5;
    background: #c8ebfb;
}

</style>
