<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <!-- current prototype parameters list -->
    <div class="column">

        <!-- list header -->
        <div class="column-header">
            <div class="column-header__title">
                List options <span class="column-header__title-count">( {{ propertyListOptions.length }} )</span>
            </div>
        </div>

        <!-- list search -->
        <div class="list-search">
            <!-- @todo : search field -->
        </div>

        <!-- options -->
        <perfect-scrollbar class="list-content">
            <div v-for="element in propertyListOptions" :key="element.id" class="list-element relative" :class="{'list-element--selected': element.id == $route.params.option_id}">

                <div class="list-element__prefix list-element__handle">
                    <div class="list-element__btn">
                        <lsn-icon iname="lsn-sort" />
                    </div>
                </div>
                <div class="list-element__content py-1 px-2" @click="selectOption(element)">
                    <div class="list-element__label w-full" v-html="element.label"></div>
                    <div class="list-element__code w-full">
                        {{ element.code }}
                    </div>
                </div>
                <div class="list-element__suffix">
                    <div class="list-element__btn" @click="true">
                        <lsn-icon iname="lsn-close" />
                    </div>
                </div>
                
            </div>
        </perfect-scrollbar>

    </div>

    <router-view></router-view>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'ListOption',

    computed:
    {
        propertyListOptions()
        {
            return this.$store.state.prototype.propertyListOptions;
        }
    },

    methods:
    {
        selectOption(option)
        {
            this.$router.push({
                name:   'prototype-parameter-design-group-property-list-options-parameter', 
                params: _.assign({}, this.$route.params, {option_id: option.id})
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
