<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="list-element" :class="{'list-element--selected': parameter.id == $route.params.parameter_id}">

        <!-- prefix (sort handle) -->
        <div class="list-element__prefix list-element__handle">
            <div class="list-element__btn">
                <lsn-icon iname="lsn-sort" />
            </div>
        </div>

        <!-- content -->
        <div class="list-element__content py-1 px-2" @click="selectParameter()">
            <div class="list-element__label w-full" v-html="parameter.label"></div>
            <div class="list-element__code w-full">
                {{ parameter.code }}
            </div>
        </div>

        <!-- suffix (delete button) -->
        <div class="list-element__suffix">
            <div class="list-element__btn" @click="showDeletionConfirmation = true">
                <lsn-icon iname="lsn-close" />
            </div>
        </div>

    </div>

    <!-- delete confirmation modal -->
    <teleport to="body">
        <lsn-modal :is-open="showDeletionConfirmation">
            <div class="lsn-prompt">

                <div class="lsn-prompt-content">
                    <div class="lsn-prompt-content__question text-red-600">
                        Do you really want to remove this parameter from this prototype ?
                    </div>
                    <div class="lsn-prompt-content__text">
                        This action cannot be undone and all properties of this parameter will be removed.
                    </div>
                </div>

                <div class="lsn-prompt-footer justify-around py-4">
                    <button class="lsn-btn  lsn-btn--gray-outline" @click="showDeletionConfirmation = false">
                        cancel
                    </button>
                    <button class="lsn-btn lsn-btn--danger" @click="removeParameterFromPrototype()">
                        delete
                    </button>
                </div>

            </div>
        </lsn-modal>
    </teleport>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';

export default
{
    name: 'PrototypeParametersListItem',

    props:
    {
        parameter:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['parameter:remove'],

    data()
    {
        return {
            showDeletionConfirmation: false
        };
    },

    methods:
    {
        selectParameter()
        {
            let promises = [];

            promises.push(this.$store.dispatch('prototype/fetchParameterProperties',{
                prototype_id: this.$route.params.prototype_id, 
                parameter_id: this.parameter.id
            }));

            promises.push(this.$store.dispatch('prototype/fetchParameterAllListOptions', this.parameter.id));
            
            Promise.all(promises).then(() =>
            {
                this.$router.push({
                    name:   'prototype-parameter-design-groups', 
                    params: {
                        prototype_id: this.$route.params.prototype_id,
                        parameter_id: this.parameter.id
                    }
                });
            });
        },

        removeParameterFromPrototype()
        {
            this.$emit('parameter:remove', this.parameter);
            this.showDeletionConfirmation = null;
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
