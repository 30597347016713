<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="flex h-screen w-full justify-center bg-primary-10">
        <div class="text-center" style="margin-top: 20%">
            <div class="mb-8 text-primary-500 text-4xl">
                CS-Designer
            </div>
            <img class="inline" src="/img/initializing_loader.gif">
            <div class="mt-8 text-lg text-gray-400">
                loading in progress, thank you for waiting
            </div>
        </div>
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Initializing'
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">



</style>
