<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="lsn-login">

        <!-- left side -->
        <div class="lsn-login-side">

            <!-- logo & title -->
            <div class="lsn-login-side__title">
                CS-Designer
            </div>
            <div class="lsn-login-side__subtitle">
                by Constellation-Services
            </div>

            <!-- footer -->
            <div class="lsn-login-side__footer">
                Constellation-Services<br>&copy; {{ year }} - All rights reserved
            </div>

        </div>

        <!-- right side (image) -->
        <div class="lsn-login__image"></div>

        <!-- login box -->
        <div class="lsn-login__form">

            <div class="lsn-login__form-content">

                <!-- username -->
                <div class="lsn-form__wrapper">
                    <label class="lsn-form__label" for="username">
                        Username
                    </label>
                    <input id="username" v-model="credentials.username" type="text" class="lsn-form__input w-full" name="username" autocapitalize="off" autocomplete="off" autofocus @keyup.enter="authenticate()">
                </div>

                <!-- password -->
                <div class="lsn-form__wrapper">
                    <label class="lsn-form__label" for="password">
                        Password
                    </label>
                    <input id="password" v-model="credentials.password" type="password" class="lsn-form__input w-full" name="password" @keyup.enter="authenticate()">
                </div>

                <!-- error -->
                <div v-if="authError" class="bg-red-100 text-red-500 text-center text-sm py-2 mt-3">
                    {{ authError }}
                </div>

                <!-- button and spinner -->
                <div class="lsn-form__wrapper text-center mt-3">
                    <button v-if="!authenticating" class="lsn-btn lsn-btn--primary w-32 mx-auto" @click="authenticate()">
                        sign in
                    </button>
                    <div v-else class="lsn-btn lsn-btn--primary w-32 mx-auto">
                        <img class="block m-auto" src="/img/loader_white.gif">
                    </div>
                </div>

            </div>

        </div>

        <!-- server error -->
        <div v-if="hasServerError" class="fixed inset-0 bg-primary-200 opacity-90 flex justify-center">
            <div class="w-1/3" style="margin-top: 20%;">
                <div class="mb-2 text-4xl text-primary-500">
                    Oops
                </div>
                <div class="mb-6 text-2xl text-primary-500">
                    Well, this is unexpected...
                </div>
                <div class="text-gray-600">
                    <p class="mb-4">
                        An error has occurred, we're working to fix the problem ! Thank you for your patience !
                    </p>
                    <p class="mb-4">
                        In the mean time, try to reload the page.
                    </p>
                </div>
            </div>  
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Login',
    
    emits: ['initialize'],

    data()
    {
        return {
            authenticating: false,
            authErrorCode:  null,
            hasServerError: false,
            credentials:    {
                username: null,
                password: null
            },
            year: new Date().getFullYear(),
        };
    },

    computed:
    {
        authError()
        {
            switch(this.authErrorCode)
            {
                case 102:
                    return 'User does not exist';

                case 103:
                    return 'Wrong password';
                    
                case 104:
                    return 'This user is disabled. For more information, please contact our customer service.';
                    
                case 105:
                    return 'This account has expired. For more information, please contact our customer service.';
                    
                case 108:
                    return 'This user is not authorized to connect to this service.';
            }

            return null;
        }
    },

    methods:
    {
        authenticate()
        {
            this.authenticating = true;
            this.authErrorCode = null;

            this.$store.dispatch('auth/authenticate', this.credentials).then(() =>
            {
                this.$emit('initialize');

                this.$router.push({
                    name:   'initializing', 
                    params: this.$route.params,
                    query:  this.$route.query
                });
            }).catch((error) =>
            {
                this.authenticating = false;
                
                if(error.response && error.response.status != 500)
                {
                    this.authErrorCode = parseInt(error.response.errors);
                }
                else
                {
                    this.hasServerError = true;
                    this.$honeybadger.notify(error);
                }
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>


</style>
